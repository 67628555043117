<template>
  <div class="background-body">
    <v-container fluid>
      <v-row justify="center" align="center" class="background-card px-3">
        <v-col cols="8" class="col-auto">
          <notification-popup
            :dialog="notifFailed"
            :contentMsg="$t('FailedRepay')"
            :headerMsg="$t('failed')"
            :nameBtnNo="$t('labelNo')"
            :nameBtnYes="$t('Close')"
            colorIcon="danger"
            typeModal="transaction"
            Icon="mdi-close-circle"
            disableBtnNo="true"
            imgSource="notification/failed.svg"
            :onHandlerYes="buttonOkeFailed"
          />
          <v-row>
            <v-col cols="12" class="py-0">
              <b>{{ $t("TransactionHistory") }}</b>
              <br />
              <span class="body-2">{{ items.inv_no }}</span>
            </v-col>
          </v-row>
          <v-row
            align="center"
            class="pa-3 pt-0 background-card"
            v-if="items.payment_channel_name"
          >
            <v-col cols="12" class="pb-1 pl-0">
              <b>{{ $t("titlePaymentMethod") }}</b>
            </v-col>
            <v-col
              cols="2"
              class="py-0 pl-0 pr-1"
              v-if="items.payment_channel_name == 'momo'"
            >
              <v-img :src="require('@/assets/momo.png')"> </v-img>
            </v-col>
            <v-col
              cols="2"
              class="py-0 pl-0 pr-1"
              v-if="items.payment_channel_name == 'moca'"
            >
              <v-img :src="require('@/assets/moca.png')"> </v-img>
            </v-col>
            <v-col
              cols="2"
              class="py-0 pl-0 pr-1"
              v-if="items.payment_channel_name == 'atm card' || items.payment_channel_name == 'credit/debit card'"
            >
              <v-img :src="require('@/assets/zalopay.png')"> </v-img>
            </v-col>
            <v-col
              cols="10"
              class="pl-0 py-0"
              v-if="items.payment_channel_name"
            >
              {{
                items.payment_channel_name === ""
                  ? ""
                  : items.payment_channel_name
              }}
            </v-col>
            <br />
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <b>
                <h4>{{ $t("Status") }}</h4>
              </b>
              <h5>
                {{
                  items.inv_payment_status == "WaitingPayment"
                    ? $t("Waitingforpayment")
                    : $t(items.inv_payment_status)
                }}
                <h5>
                    {{items.created_at
                }}
                </h5>
                
              </h5>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" class="col-auto">
          <v-img
            v-if="items.inv_payment_status == 'Success'"
            :src="require('@/assets/transactions/success.svg')"
          >
          </v-img>
          <v-img
            v-if="items.inv_payment_status == 'Failed' || items.inv_payment_status == 'Canceled'"
            :src="require('@/assets/transactions/failed.svg')"
          >
          </v-img>
          <v-img
            v-if="items.inv_payment_status == 'Pending' "
            :src="require('@/assets/transactions/pending.svg')"
          >
          </v-img>
          <v-img
            v-if="items.inv_payment_status == 'Expired'"
            :src="require('@/assets/transactions/expired.svg')"
          >
          </v-img>
          <v-img
            v-if="items.inv_payment_status == 'WaitingPayment'"
            :src="require('@/assets/transactions/pending.svg')"
          >
          </v-img>
        </v-col>
      </v-row>
      <br />
      <hr />
      <Invoices :listInvoices="items.inv_detail" />
       <!-- v-if="items.inv_discount > 0"  -->
      <v-row  v-if="items.inv_discount > 0" class="pl-2 pr-2 background-card font-weight-medium">
          <v-col cols="6" class="pt-0 pb-1 body-2">
              <b>{{$t('Discount')}}</b>
          </v-col>
          <v-col cols="6" class="text-end pt-0 pb-1 body-2">
              <b>{{ Intl.NumberFormat('de-DE', { style: 'currency', currency: items.currency }).format(items.inv_discount) }}</b>
          </v-col>
      </v-row>

      <v-row  v-if="pay && discount > 0" class="pl-2 pr-2 background-card font-weight-medium pt-2">
          <v-col cols="6" class="pt-0 pb-1 body-2">
              <b>{{$t('Discount')}}</b>
          </v-col>
          <v-col cols="6" class="text-end pt-0 pb-1 body-2">
              <b>{{ Intl.NumberFormat().format(discount) }} VND</b>
          </v-col>
      </v-row>
      <v-row v-if="issuer_service_fee > 0" class="pl-2 pr-2 background-card font-weight-medium pt-3">
          <v-col cols="6" class="pt-0 pb-1 body-2">
              <b class="body-2">{{$t('IssuerServiceFee')}}</b>
          </v-col>
          <v-col cols="6" class="text-end pt-0 pb-1 body-2">
              <b>{{ Intl.NumberFormat().format(issuer_service_fee) }} VND</b>
          </v-col>
      </v-row>
      <v-row class="pa-2 background-card">
        <v-col cols="6">
          <b>{{ $t("TotalPayment") }}</b>
        </v-col>
        <v-col cols="6" class="text-right">
          <b>{{ Intl.NumberFormat('de-DE', { style: 'currency', currency: items.currency }).format(items.inv_amount) }}</b>
        </v-col>
      </v-row>
      <div class="footer" v-if="pay">
        <v-container>
          <v-btn
            :color="$partnerACL.getButtonColor()"
            rounded
            width="100%"
            :disabled="disableButton"
            class="text-capitalize"
            v-on:click="payNow(items.inv_detail)"
            >{{ $t("PayNow") }}
          </v-btn>
        </v-container>
      </div>
    </v-container>
  </div>
</template>

<script>
import Invoices from "@/components/Invoices.vue";
import NotificationPopup from "@/components/NotificationPopup.vue";
// import moment from "moment";
export default {
  name: "HistoryDetail",
  // props: {
  //   invoiceID: {
  //     type: String,
  //     required: true,
  //   },
  // },
  data: () => {
    return {
      notifFailed: false,
      amount: "",
      inv_amount: "",
      amountWithIssuer: "",
      totalPayment: "",
      disableButton: true,
      discount: "",
      items: {
        inv_no: "",
        inv_amount: "",
        inv_sub_amount: "",
        inv_discount: "",
        inv_trx_id: "",
        inv_detail_qty: "",
        inv_payment_status: "",
        inv_customer_id: "",
        payment_channel_name: "",
        created_at: "",
        icon: "",
        inv_detail: [],
      },
      amount_issuer_fee: "",
      issuer_service_fee: "",
      pay: false,
      timestamp: "",
      limitedTime: "",
    };
  },
  components: {
    Invoices,
    NotificationPopup,
  },
  computed: {
    selectedCurrency() {
      return this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.code : ""
    },
    discountData() {
      return store.state.purchaseticket.discount
    },
  },
  methods: {
    // addData() {
    //   var payload = {
    //     inv_no: this.$route.params.invoiceNO,
    //   };
    //   this.$store
    //     .dispatch("purchaseticket/getTransactionHistoryDetail", payload)
    //     .then((response) => {
    //       this.limitedTime = moment
    //         .utc(response.created_at)
    //         .add(60, "minutes")
    //         .format("DD MMMM YYYY hh:mm:ss");
    //       this.pay =
    //         this.limitedTime > moment().format("DD MMMM YYYY hh:mm:ss");
    //       // console.log(this.limitedTime, this.pay);
    //       // console.log(
    //       //   this.limitedTime,
    //       //   moment().format("DD MMMM YYYY hh:mm:ss")
    //       // );
    //       this.items.inv_no = response.inv_no;
    //       this.items.inv_trx_id = response.inv_trx_id;
    //       this.items.inv_amount = response.inv_amount;
    //       this.amount_issuer_fee = response.amount_issuer_fee;
    //       this.issuer_service_fee = response.issuer_service_fee;
    //       this.inv_amount = response.inv_amount;
    //       this.items.inv_discount = response.inv_discount;
    //       this.items.inv_sub_amount = response.inv_sub_amount;
    //       this.items.inv_detail_qty = response.inv_detail_qty;
    //       this.items.code_satus = response.inv_payment_status;
    //       if (response.inv_payment_status == "paid") {
    //         if (response.generate_ticket == false) {
    //           this.items.inv_payment_status = "Failed";
    //         }
    //         if (response.generate_ticket != false) {
    //           this.items.inv_payment_status = "Success";
    //         }
    //         this.pay = false;
    //         this.items.icon = "success";
    //       } else if (response.inv_payment_status == "pending") {
    //         this.items.inv_payment_status = "Pending";
    //         this.items.icon = "pending";
    //         this.pay = false;
    //       } else if (response.inv_payment_status == "fail") {
    //         this.items.inv_payment_status = "Failed";
    //         this.items.icon = "failed";
    //       } else if (response.inv_payment_status == "waitingpayment") {
    //         this.items.inv_payment_status = "WaitingPayment";
    //         this.items.icon = "pending";
    //         this.pay = false;
    //       } else if (response.inv_payment_status == "canceled") {
    //         this.items.inv_payment_status = "Canceled";
    //         this.items.icon = "failed";
    //       } else if (response.inv_payment_status == "expired") {
    //         this.items.inv_payment_status = "Expired";
    //         this.items.icon = "refund";
    //         this.pay = false;
    //       }
    //       // this.totalPayment = parseFloat(0)
    //       this.items.inv_psg_id = response.inv_psg_id;
    //       this.items.payment_channel_name = response.payment_channel_name;
    //       this.items.created_at = moment
    //         .utc(response.created_at)
    //         .format("DD MMMM YYYY HH:mm");
    //       this.items.inv_detail = response.inv_detail;
    //       if (this.items.inv_payment_status == "WaitingPayment" && this.pay && this.items.inv_trx_id != "") {
    //         this.$store.dispatch(
    //           "purchaseticket/getStatusByPaymentToken",
    //           this.items.inv_trx_id
    //         )
    //           .then((response) => {
    //             var status =
    //               response.payment_status_histories[
    //               response.payment_status_histories.length - 1
    //               ];
    //             if (status.payment_status == "submit") {
    //               this.pay = true;
    //             } else {
    //               this.pay = false;
    //             }
    //           })
    //           .catch((err) => {
    //             console.log(err);
    //           });
    //       }

    //       // console.log(this.$route.query.payment_token);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    convertPriceByCurrency(price, currency) {
      const conversionRates = this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.conversionRates : null;
      const priceResult = price * conversionRates[currency];
      return priceResult;
    },
    addData() {
      try {
        if (this.$route.params.details) {
          const detail = this.$route.params.details
          this.items.inv_no = detail.invoice_number
          this.items.inv_trx_id = detail.trx_id;
          this.items.inv_amount = this.convertPriceByCurrency(detail.price_after_discount, detail.currency_code);
          this.amount_issuer_fee = detail.amount_issuer_fee;
          this.issuer_service_fee = detail.issuer_service_fee;
          this.inv_amount = this.convertPriceByCurrency(detail.price_after_discount, detail.currency_code);
          this.items.inv_discount = this.convertPriceByCurrency(Number(detail.discount_price), detail.currency_code);
          this.items.inv_sub_amount = detail.inv_sub_amount;
          this.items.inv_detail_qty = detail.qty;
          this.items.inv_payment_status = detail.inv_payment_status;
          this.items.inv_customer_id = detail.customer_id
          this.items.inv_detail = [
            {
              fare_name: detail.fare_name,
              currency: detail.selectedCurrency,
              price: detail.convertedPrice,
              qty: detail.qty
            }
          ];
          this.items.currency = detail.selectedCurrency
          this.items.payment_status = detail.payment_status
          this.items.cart_id = detail.customer_cart_id
          this.items.payment_channel_name = detail.payment_channel_name

          if (detail.payment_status == 'submit') {
            this.pay = true
            this.disableButton = false
          }
        } else {
          this.$router.go(-1)
        }
      } catch (error) {
        console.log(error)
      }
    },
    payNow() {
      const payload = {
        customer_cart_ids: this.items.cart_id,
        language: this.$i18n.locale,
        price_total: this.items.inv_amount,
        currency_code: this.selectedCurrency,
        discount: this.discountData
      };

      this.$store
        .dispatch("purchaseticket/checkout", payload)
        .then((response) => {
          if (response.status) console.log("success", response.status);
          window.location.href = response.url;
        })
        .catch((err) => {
          // this.notifFailed = true;
          console.log(err);
        });

      // if (this.limitedTime > moment().format("DD MMMM YYYY hh:mm:ss")) {
      // /* Hit API checkout */
      // } else {
      //   this.notifFailed = true;
      //   this.$router.push({ name: "TransactionHistory" });
      // }
    },
    buttonOkeFailed() {
      this.notifFailed = false;
    },
  },
  watch: {
    inv_amount() {

      // this.$store
      //   .dispatch("purchaseticket/checkAvailableCampaignOtomatis")
      //   .then((resp) => {

      //     if (resp.status === true && resp.type === "Percentage" && this.pay == true) {
      //       this.discount = parseFloat(this.inv_amount) * (resp.value / 100)
      //       this.totalPayment = parseFloat(this.inv_amount) - this.discount
      //       // console.log("Percentage ",this.totalPayment)
      //     } else if (resp.status === true && resp.type === "Amount" && this.pay == true) {
      //       this.discount = resp.value
      //       this.totalPayment = parseFloat(this.inv_amount) - resp.value
      //       // console.log("Amount ",this.totalPayment)
      //     } else {
      //       this.discount = resp.value
      //       this.totalPayment = parseFloat(this.inv_amount)
      //       // console.log("else  ",this.totalPayment)
      //     }
      //     // this.totalPayment = parseFloat(0)

      //   }).then(() => {
      //     this.amountWithIssuer = this.totalPayment + this.issuer_service_fee
      //     this.disableButton = false;
      //   })
      //   .catch((err) => {
      //     this.disableButton = true;
      //     console.log(err);
      //   });
    }
  },
  mounted() {
    this.addData();
  },
};
</script>
<style scoped>
hr {
  border: 0.5px solid #f5f5f5;
}

.h4-black {
  font-size: 16px;
  color: #000;
}

.text-black {
  color: #000 !important;
}

.text-orange {
  color: #f29900;
}

.background-body {
  background-color: rgba(245, 255, 247, 1);
}

.background-card {
  background-color: rgb(255, 255, 255);
}
</style>

<template>
    <v-container class="pa-0">
        <div v-for="(value, index) in listInvoices" :key="index">
            <v-row class="pl-2 pr-2 background-card">
                <v-col cols="12">
                    <b class="body-1 font-weight-bold">{{value.fare_name}}</b>
                </v-col>
            </v-row>
            <v-row class="pl-2 pr-2 background-card">
                <v-col cols="6" class="pt-0 pb-1 body-2">
                    {{$t('TotalQuantity')}}
                </v-col>
                <v-col cols="6" class="text-end pt-0 pb-1">
                    {{Intl.NumberFormat().format(value.qty)}}
                </v-col>
            </v-row>
            <v-row class="pl-2 pr-2 background-card font-weight-medium">
                <v-col cols="6" class="pt-0 pb-1 body-2">
                    {{$t('TicketPrice')}}
                </v-col>
                <v-col cols="6" class="text-end pt-0 pb-1 text-orange body-2">
                    <b>{{ Intl.NumberFormat('de-DE', { style: 'currency', currency: value.currency }).format(value.price) }}</b>
                </v-col>
            </v-row>
            <v-row class="pl-2 pr-2 background-card font-weight-medium">
                <v-col cols="6" class="body-2">
                    <b>{{$t('TotalSub')}}</b>
                </v-col>
                <v-col cols="6" class="text-end body-2">
                    <b>{{ Intl.NumberFormat('de-DE', { style: 'currency', currency: value.currency }).format(value.price * value.qty) }}</b>
                </v-col>
            </v-row>
            <hr>
            
        </div>
    </v-container>
</template>

<script>
export default {
    name: "Invoices",
    props:['listInvoices','payload'],
    data: function () {
        return {
            discount:0,
            ticketType : {
                "01" : "Single Trip",
                "02" : "Round Trip",
                "03" : "Multi Trip",
                "04" : "Commuter Pass",
                "05" : "Student Pass",
            }
        }
    },
    mounted(){
        
    }
}
</script>
<style scoped>
    hr {
        border: 0.5px solid #F5F5F5;
    }
    .text-orange {
        color: #F29900;
    }
    .background-card{
        background-color: rgb(255, 255, 255);
    }
</style>